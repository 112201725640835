import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Contact us today to have a free quote!
        </p>
        {/* <p className='footer-subscription-text'>
          Please ask us if you have any questions.
        </p> */}
      </section>

      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h3>Services</h3>
            <Link
              to='/services'
              title='footer whole house remodeling service link'
            >
              Whole House Remodeling
            </Link>
            <Link
              to='/services'
              title='footer renovation consultant service link'
            >
              Renovation Consultant
            </Link>
            <Link to='/services' title='footer kitchen renovation service link'>
              Kitchen Renovation
            </Link>
            <Link
              to='/services'
              title='footer bathroom renovation service link'
            >
              Bathroom Renovation
            </Link>
            <Link
              to='/services'
              title='footer basement renovation service link'
            >
              Basement Renovation
            </Link>
            <Link
              to='/services'
              title='footer house architecture design service link'
            >
              House Architecture Design
            </Link>
            <Link
              to='/services'
              title='footer home interior design service link'
            >
              Home Interior Design
            </Link>
            <Link to='/services' title='footer electrical service link'>
              Electrical Services
            </Link>
            <Link to='/services' title='footer separate entrances service link'>
              Side & Separate Entrances
            </Link>
          </div>

          <div className='footer-link-items'>
            <h3>Company</h3>
            <Link to='/about-us' title='footer about us link'>
              About Us
            </Link>
            <Link to='/portfolio' title='footer portfolio link'>
              Portfolio
            </Link>
            <Link to='/terms-of-service' title='footer term of service link'>
              Terms of Service
            </Link>
            <Link to='/privacy-policy' title='footer privacy policy link'>
              Privacy Policy
            </Link>
          </div>

          <div className='footer-link-items'>
            <h3>Contact</h3>
            <div className='bottom-contact'>
              <FontAwesomeIcon className='icon-phone' icon={faPhoneVolume} />
              {/* <Link to='/contact'>(905) 490-8089</Link> */}
              <p>
                <a href='tel:+19054908089' title='contact phone number link'>
                  (905) 490-8089
                </a>
              </p>
            </div>
            <div className='bottom-contact'>
              <FontAwesomeIcon className='icon-email' icon={faEnvelope} />
              <Link to='/contact' title='footer email link'>
                support@trureno.com
              </Link>
            </div>
          </div>

          {/* <div className='footer-link-items'>
            <h2>xxxx</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Services</Link>
          </div> */}
        </div>
      </div>

      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link
              to='/'
              className='social-logo'
              title='footer trureno logo link'
            >
              TRURENO
              <img
                className='truReno-logo'
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/truReno-logo-circle.png'
                alt='trureno footer logo'
                title='trureno footer logo'
              />
            </Link>
          </div>

          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook Link'
              title='footer social facebook link'
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>

            <Link
              className='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram Link'
              title='footer social instagram link'
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>

            <Link
              className='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter Link'
              title='footer social twitter link'
            >
              <FontAwesomeIcon icon={faTwitter} />
            </Link>

            <Link
              className='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube Link'
              title='footer social youtube link'
            >
              <FontAwesomeIcon icon={faYoutube} />
            </Link>

            <Link
              className='social-icon-link linkedin'
              to='/'
              target='_blank'
              aria-label='LinkedIn Link'
              title='footer social linkedin link'
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
          </div>

          <small className='website-rights'>TRURENO &copy; 2024</small>
        </div>
      </section>
    </div>
  );
};

export default Footer;
