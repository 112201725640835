import React from "react";
import Footer from "../../components/Footer/Footer";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import { Seo } from "../../helper/SEO";

const KitchenProject = () => {
  const kitchenImages = [
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/img-hero.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8715.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8694.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-10.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-14.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-1.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-2.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-3.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-5.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-6.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-7.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-8.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-9.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-11.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-12.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-18.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-13.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-15.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-16.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-17.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-19.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-4.webp",
  ];

  const headerTitle = "Kitchen Remodeling";

  const projectDescription =
    "Looking for remodeling your kitchen with expert? From sleek countertops to custom cabinetry, we create stylish spaces tailored to your taste and lifestyle!";

  return (
    <>
      <Seo
        title='TruReno | Renovate your kitchen to any style you like'
        description='Revamp your kitchen with TruReno! From sleek countertops to custom cabinetry, we create stylish spaces tailored to your taste and lifestyle!'
        canonicalUrl='https://trureno.com/portfolio/kitchen-project'
      />
      <ImageCarousel
        slides={kitchenImages}
        headerTitle={headerTitle}
        description={projectDescription}
        hrefId={"portfolio#portfolio__cards"}
      />
      <Footer />
    </>
  );
};

export default KitchenProject;
