import React from "react";
import Footer from "../../components/Footer/Footer";
import About from "../../components/About/About.jsx";
import Principles from "../../components/Principles/Principles.jsx";
import { Seo } from "../../helper/SEO.js";

const AboutUs = () => {
  return (
    <>
      <Seo
        title='TruReno | Your budget-friendly, trusted contractor'
        description='Opt for TruReno for a stress-free and affordable renovation experience. Explore our hassle-free planning approaches for your project.'
        canonicalUrl='https://trureno.com/about-us'
      />
      <About />
      <Principles />
      <Footer />
    </>
  );
};

export default AboutUs;
