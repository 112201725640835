import React from "react";
import Footer from "../../components/Footer/Footer";
import PortfolioCards from "../../components/PortfolioCards/PortfolioCards.jsx";
import { Seo } from "../../helper/SEO.js";

const Portfolio = () => {
  return (
    <>
      <Seo
        title='TruReno | We remodel the project as per your specifications'
        description='Explore the beauty of a fully renovated home, or the kitchen and any other living spaces. Share your vision, and let us create your dream home together.'
        canonicalUrl='https://trureno.com/portfolio'
      />
      <PortfolioCards />
      <Footer />
    </>
  );
};

export default Portfolio;
