import React from "react";
import "./Home.css";
import HeroSection from "../../components/HeroSection/HeroSection";
import ProjectCards from "../../components/ProjectCards/ProjectCards";
import Footer from "../../components/Footer/Footer";
import Mission from "../../components/Mission/Mission";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import { Seo } from "../../helper/SEO.js";

const Home = () => {
  return (
    <>
      <Seo
        title='TruReno | Home Renovation for Kitchen, Bathroom & More'
        description='As your trusted contractor, TruReno provides high-quality services tailored to your budget. Our services encompass renovation for Kitchen, Bathroom, Basement and More.'
        canonicalUrl='https://trureno.com/'
      />
      <HeroSection />
      <WhyChooseUs />
      <ProjectCards />
      <Mission />
      <Footer />
    </>
  );
}

export default Home;
