import CardItem from "../CardItem/CardItem";
import "./ProjectCards.css";
import capitalizeFun from "../../helper/helper";
import { Link } from "react-router-dom";

function ProjectCards() {
  const projectTitle = "Check our recent projects!";
  return (
    <div className='project__cards' id='project__cards'>
      <h3> {capitalizeFun(projectTitle)} </h3>
      <div className='project__cards__container'>
        <div className='project__cards__wrapper'>
          <ul className='project__cards__items'>
            <CardItem
              src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8675.webp'
              path='/recent-project-oakville'
            />
          </ul>
        </div>

        <Link className='portfolio-internal-link' to='/portfolio'>
          Check our portfolio page for more demo...
        </Link>
      </div>
    </div>
  );
}

export default ProjectCards;
