import React from 'react'
import Footer from "../../components/Footer/Footer";
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import { Seo } from '../../helper/SEO';

const OakvilleProject = () => {
    const oakvilleImages = [
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8327.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8339.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8609.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8661.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8666.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8675.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8684.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8694.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8715.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8731.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8745.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8746.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8748.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8750.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8729.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8205.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8272.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8374.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8342.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8361.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8447.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8451.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8472.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8474.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8608.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8727.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8878.webp",
    ];

    const headerTitle = "Whole House Complete Remodeling";

    const projectDescription =
      "We built this house with a new open-concept kitchen, high-quality wood floors, updated paint, and ceilings. Bathrooms feature new vanities and lighting.";

  return (
    <>
      <Seo
        title='TruReno | Check out our ongoing renovation project!'
        description='We built this house with a new open-concept kitchen, high-quality wood floors, updated paint, and ceilings. Bathrooms feature new vanities and lighting.'
        canonicalUrl='https://trureno.com/recent-project-oakville'
      />
      <ImageCarousel
        slides={oakvilleImages}
        headerTitle={headerTitle}
        description={projectDescription}
        hrefId={"#project__cards"}
      />
      <Footer />
    </>
  );
}

export default OakvilleProject;