import React, { useState } from "react";
import capitalizeFun from "../../helper/helper";
import "./ImageCarousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCirclePause } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Button/Button";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./ImageCarousel.css";

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";
import { HashLink } from "react-router-hash-link";

const ImageCarousel = ({ slides, headerTitle, description, videoSource, hrefId }) => {

  const [pause, setPause] = useState(false);
  const [heroSwiper, setSwiperRef] = useState(null);

  const playHero = () => {
    heroSwiper.autoplay.start();
    setPause(false);
  };
  const pauseHero = () => {
    heroSwiper.autoplay.stop();
    setPause(true);
  };

  return (
    <>
      <div className='carousel-component-container'>
        <div className='carousel-container'>
          <div className='close-btn-wrap'>
            {/* HashLink of react-router-hash-link is used to link to specific section of the another page as React Router Dom Link tag failed to go to specific section */}
            <HashLink className='close-carousel' to={`/${hrefId}`}>
              <span>&times;</span>
            </HashLink>
          </div>
          {!!slides ? (
            <>
              <Swiper
                slidesPerView={1}
                onSwiper={setSwiperRef}
                loop={true}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={{
                  type: "fraction",
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className='carousel-frame'
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <img className="swiper__slide__img" src={slide} alt={`slide ${index}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='carousel-play'>
                {!!pause ? (
                  <FontAwesomeIcon icon={faCirclePlay} onClick={playHero} />
                ) : (
                  <FontAwesomeIcon icon={faCirclePause} onClick={pauseHero} />
                )}
              </div>
            </>
          ) : (
            <video
              // width="60%"
              src={videoSource}
              autoPlay
              loop
              muted
              type='video/mp4'
            />
          )}
        </div>
      </div>

      {/* <div className='svg-bottom '>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 0 1000 78'
          preserveAspectRatio='none'
          data-position='bottom'
          data-ct='36510'
          decoration-type='Waves 01'
          data-css='tve-u-620e6e5f554b36'
        >
          <path d='M0,0v60c51.1-27,123.1-42,216-45c122-4,207.4,27.3,443,38c72.6,3.3,186.3-4.4,341-23V0H0z'></path>
          <path
            opacity='0.6'
            d='M1,1v60c23.1-17,81.1-27,174-30c122-4,169.4,32.3,405,43c72.6,3.3,213-11,421-43V1H1z'
          ></path>
          <path
            opacity='0.2'
            d='M1,0v62c17.8-9,73.1-15,166-18c122-4,188,18,366,18c62,0,147.7-9,314-9     c32.1,0,83.4,6,154,18V0H1z'
          ></path>
        </svg>
      </div> */}

      <h1 className='carousel-desc-header'>{capitalizeFun(headerTitle)}</h1>
      <div className='carousel-description'>
        <h2>{description}</h2>

        <div className='carousel-btns'>
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            pageURL='/contact'
          >
            Contact Us Now
          </Button>
        </div>
      </div>
    </>
  );
};

export default ImageCarousel;
