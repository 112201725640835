import React, { useEffect } from "react";
import "./Principles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-regular-svg-icons";

function Principles() {
  useEffect(() => {
    const listItems = document.getElementsByClassName("principle-list-item");
    const listArr = Array.from(listItems);

    const fadeIn = (item, delay) => {
      setTimeout(() => {
        item.classList.add("fadein");
      }, delay);
    };

    listArr.forEach((item, index) => {
      fadeIn(item, index * 700);
    });
  }, []);

  return (
    <>
      <section className='principle-section'>
        <h3>You Can Count On Us</h3>
        <div className='principle-container'>
          <div className='principle-wrapper'>
            <div className='principle-inner-container'>
              <div className='principle-left-side'>
                <ul className='principle-list-container'>
                  <li className='principle-list-item'>
                    <div className='choose-icon-wrapper'>
                      <span className='choose-list-icon'>
                        <FontAwesomeIcon icon={faHandPointRight} />
                      </span>
                    </div>
                    <div className='choose-text-wrapper'>
                      <p className='choose-text-title'>Transparency</p>
                      <p className='choose-text'>
                        We believe in open communication and transparency at
                        every stage of the renovation process. You'll always be
                        informed and involved.
                      </p>
                    </div>
                  </li>

                  <li className='principle-list-item'>
                    <div className='choose-icon-wrapper'>
                      <span className='choose-list-icon'>
                        <FontAwesomeIcon icon={faHandPointRight} />
                      </span>
                    </div>
                    <div className='choose-text-wrapper'>
                      <p className='choose-text-title'>Customer Satisfaction</p>
                      <p className='choose-text'>
                        Your satisfaction is our priority. We go the extra mile
                        to ensure your expectations are not just met but
                        exceeded.
                      </p>
                    </div>
                  </li>

                  <li className='principle-list-item'>
                    <div className='choose-icon-wrapper'>
                      <span className='choose-list-icon'>
                        <FontAwesomeIcon icon={faHandPointRight} />
                      </span>
                    </div>
                    <div className='choose-text-wrapper'>
                      <p className='choose-text-title'>Timely Delivery</p>
                      <p className='choose-text'>
                        We value your time as much as ours. Our efficiency and
                        punctuality ensure projects are completed within
                        agreed-upon timelines.
                      </p>
                    </div>
                  </li>

                  <li className='principle-list-item'>
                    <div className='choose-icon-wrapper'>
                      <span className='choose-list-icon'>
                        <FontAwesomeIcon icon={faHandPointRight} />
                      </span>
                    </div>
                    <div className='choose-text-wrapper'>
                      <p className='choose-text-title'>Respect for Property</p>
                      <p className='choose-text'>
                        We treat your home with the utmost care and respect,
                        maintaining cleanliness and order throughout the
                        renovation process.
                      </p>
                    </div>
                  </li>

                  <li className='principle-list-item'>
                    <div className='choose-icon-wrapper'>
                      <span className='choose-list-icon'>
                        <FontAwesomeIcon icon={faHandPointRight} />
                      </span>
                    </div>
                    <div className='choose-text-wrapper'>
                      <p className='choose-text-title'>Teamwork</p>
                      <p className='choose-text'>
                        Our collaborative approach ensures that your ideas and
                        our expertise work together seamlessly to achieve the
                        best results.
                      </p>
                    </div>
                  </li>

                  <li className='principle-list-item'>
                    <div className='choose-icon-wrapper'>
                      <span className='choose-list-icon'>
                        <FontAwesomeIcon icon={faHandPointRight} />
                      </span>
                    </div>
                    <div className='choose-text-wrapper'>
                      <p className='choose-text-title'>Aftercare & Support:</p>
                      <p className='choose-text'>
                        Our commitment doesn't end when the project does. We
                        provide aftercare support, ensuring your continued
                        satisfaction.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='principle-right-side'>
                <img
                  className='up-img'
                  src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/4-3/IMG_8694.webp'
                  alt=''
                />
                <img
                  className='down-img'
                  src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/4-3/IMG_8661.webp'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Principles;
