import React from "react";
import Footer from "../../components/Footer/Footer.jsx";
import Contact from "../../components/Contact/Contact.jsx";
import { Seo } from "../../helper/SEO.js";

const ContactUs = () => {
  return (
    <>
      <Seo
        title='TruReno | Contact us for a free quote on your project.'
        description='We would love to hear from you with any questions or projects you may have. Your will get help from our renovation experts, and no job is too big or too small for us!'
        canonicalUrl='https://trureno.com/contact'
      />
      <Contact />
      <Footer />
    </>
  );
};

export default ContactUs;
