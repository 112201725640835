import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import { Button } from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBars } from "@fortawesome/free-solid-svg-icons";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbarBG, setNavbarBG] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const closeMobileOpenModel = () => {
    setClick(false);
    // setModalOpen(true);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    const navBarHeight = document.getElementById("navbar")?.offsetHeight;

    const changeBackground = () => {
      if (window.scrollY >= navBarHeight - 80) {
        setNavbarBG(true);
      } else setNavbarBG(false);
    };
    window.addEventListener("scroll", changeBackground);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", showButton);
    return () => window.removeEventListener("resize", showButton);
  }, []);

  // const [modalOpen, setModalOpen] = useState(false);

  // const openModal = () => {
  //   setModalOpen(true);
  // };

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  return (
    <>
      <nav id='navbar' className={navbarBG ? "navbar navbar-scroll" : "navbar"}>
        <div className='navbar-container'>
          <Link
            to='/'
            className={
              navbarBG ? "navbar-logo navbar-logo-scroll" : "navbar-logo"
            }
            onClick={closeMobileMenu}
            title='trureno logo link'
          >
            <img
              className='truReno-logo'
              src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/truReno-logo-circle.png'
              alt='trureno company logo'
              title='trureno company logo'
            />
            <span>TruReno</span>
          </Link>

          <div className='menu-icon' onClick={handleClick}>
            {click ? (
              <FontAwesomeIcon
                icon={faXmark}
                className={navbarBG ? "fa-xmark-scroll" : "fa-xmark"}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className={navbarBG ? "fa-bars-scroll" : "fa-bars"}
              />
            )}
          </div>

          <div className='nav-menu-mobile'>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className='nav-item'>
                <NavLink
                  to='/'
                  className={
                    window.innerWidth > 960 && navbarBG
                      ? "nav-links nav-links-scroll"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                  title='navbar home link'
                >
                  <p>Home</p>
                </NavLink>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='/services'
                  className={
                    window.innerWidth > 960 && navbarBG
                      ? "nav-links nav-links-scroll"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                  title='navbar services link'
                >
                  Services
                </NavLink>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='/portfolio'
                  className={
                    window.innerWidth > 960 && navbarBG
                      ? "nav-links nav-links-scroll"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                  title='navbar portfolio link'
                >
                  Portfolio
                </NavLink>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='/about-us'
                  className={
                    window.innerWidth > 960 && navbarBG
                      ? "nav-links nav-links-scroll"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                  title='navbar about us link'
                >
                  About Us
                </NavLink>
              </li>

              <li>
                <NavLink
                  to='/contact'
                  className='nav-links-mobile'
                  onClick={closeMobileOpenModel}
                  title='navbar contact link'
                >
                  Free Quote
                </NavLink>
              </li>
            </ul>
          </div>

          {window.innerWidth > 960 && button && (
            <Button
              pageURL='/contact'
              buttonStyle={navbarBG ? "btn--primary" : "btn--outline"}
            >
              Free Quote
            </Button>
          )}
        </div>

        <div>
          {/* This is a solution to React Router's issue of not scrolling to #hash-fragments when using the <Link> component to navigate */}
          {/* <HashLink
            to='/contact#free__quote'
            className={
              navbarBG ? "promo-links promo-links-scroll" : "promo-links"
            }
            onClick={closeMobileMenu}
            title='navbar free quote link'
          >
            <div className='promo-text'>
              <img
                className='promo-img'
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/Promo_Assets/Lunar_New_Year_2024.png'
                alt='new-year-promo-img'
                title='new-year-promo-img'
              />
              <p>
                Epic Winter Sale!! Up To 10% Off - Join Us in Celebrating the
                Chinese New Year With Our Biggest Promotion. Call Us Today to
                Discover More!
              </p>
            </div>
          </HashLink> */}
        </div>
      </nav>
      {/* {modalOpen && <Modal onClose={closeModal}>xxxxxxxxx</Modal>} */}
    </>
  );
}

export default Navbar;
