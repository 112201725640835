import React, { useState } from "react";
import "./WhyChooseUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faCircleUp } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

function WhyChooseUs() {
  const [showServiceList, setShowServiceList] = useState(false);

  const handelServiceList = () => {
    setShowServiceList(!showServiceList);
  };

  return (
    <>
      <section className='choose-section'>
        <h3>Why Choose TruReno</h3>
        <div className='choose-container'>
          <div className='choose-wrapper'>
            <div className='choose-inner-container'>
              <div className='choose-left-side'>
                <img
                  className='choose-img'
                  src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-1.webp'
                  alt='The reasons for choosing trureno'
                  title='the reasons for choosing trureno'
                />
              </div>
              <div className='choose-right-side'>
                <div className='choose-text-center'>
                  <div className='primaryText'>
                    <p className='choose-content-heading'>
                      Choose TruReno for a worry-free renovation experience with
                      affordability on your budget. We handle every step
                      ourselves - that means we plan, build, and even clean up
                      when the work's done. There's no risk with half-finished
                      jobs or random subcontractors on your home project. Our
                      team is all about working closely with you to provide
                      <span>
                        <Link className='service-internal-link' to='/services'>
                          &nbsp;the best services.&nbsp;
                        </Link>
                      </span>
                      You get to call the shots, tweak the plan, and watch as
                      the house you've been dreaming of comes to life - all
                      without breaking the bank. With us, quality renovations at
                      great prices are just the way we do things.
                    </p>
                  </div>

                  <ul className='choose-list-container'>
                    <li className='choose-list-item'>
                      <div className='choose-icon-wrapper'>
                        <span className='choose-list-icon'>
                          <FontAwesomeIcon icon={faSquareCheck} />
                        </span>
                      </div>
                      <div className='choose-text-wrapper'>
                        <p className='choose-text-title'>No Hidden Fees</p>
                        <p className='choose-text'>
                          We will clearly outline all costs associated with the
                          project in the contract unless the customer requests
                          changes to the project scope, otherwise, the fees will
                          remain the same until the project is completed.
                        </p>

                        <p className='choose-text-save'>
                          We assure the project quality and collaborate closely
                          with you to lower building costs. By providing direct
                          access to our material suppliers' discounted prices
                          and eliminating middleman markups, this approach
                          allows our clients to maximize cost savings on their
                          projects without compromising quality.
                        </p>
                      </div>
                    </li>

                    <li className='choose-list-item'>
                      <div className='choose-icon-wrapper'>
                        <span className='choose-list-icon'>
                          <FontAwesomeIcon icon={faSquareCheck} />
                        </span>
                      </div>
                      <div className='choose-text-wrapper'>
                        <p className='choose-text-title'>Full-range Services</p>
                        <p className='choose-text'>
                          we pride ourselves on offering a full range of
                          services in Toronto, tailored to meet the unique needs
                          of each client. From kitchen and bathroom remodels to
                          basement developments and exterior upgrades, we
                          possess the skills and expertise to bring your vision
                          to life.
                          <button
                            onClick={handelServiceList}
                            className='service-list-btn'
                          >
                            &nbsp;...Check our services list
                          </button>
                        </p>
                        {!!showServiceList && (
                          <>
                            <ul className='service-list'>
                              <li>House Renovation / Remodeling</li>
                              <li>Home Improvements</li>
                              <li>Bath Renovation / Remodeling</li>
                              <li style={{ display: "none" }}>
                                Bathroom Renovations
                              </li>
                              <li style={{ display: "none" }}>
                                Bathroom Renovations Toronto
                              </li>
                              <li>Kitchen Renovation / Remodeling</li>
                              <li>Basement Renovation / Remodeling</li>
                              <li>Shower Remodeling</li>
                              <li>Kitchen Cabinet Refacing</li>
                              <li>Kitchen Renovation Design</li>
                              <li>Small Bathroom Redesign</li>
                              <li>House Painting</li>
                              <li>Building Construction</li>
                              <li>Floor Installation</li>
                              <li>Floor Repairing</li>
                              <li>Custom Home Building</li>
                              <li>Home Improvement</li>
                              <li>Plumbing Fixture Installation</li>
                              <li>House Interior Construction</li>
                              <li>Interior Structural Repairing</li>
                              <li>House Exterior Construction</li>
                              <li>Renovation Consultant</li>
                              <li>House Architecture Design</li>
                              <li>Interior Design</li>
                              <li>House Maintenance</li>
                              <li>Electrical Services</li>
                              <li>Side & Separate Entrances</li>
                            </ul>
                            <button
                              onClick={() => setShowServiceList(false)}
                              className='close-up-btn'
                            >
                              <FontAwesomeIcon icon={faCircleUp} />
                            </button>
                          </>
                        )}
                      </div>
                    </li>

                    <li className='choose-list-item'>
                      <div className='choose-icon-wrapper'>
                        <span className='choose-list-icon'>
                          <FontAwesomeIcon icon={faSquareCheck} />
                        </span>
                      </div>
                      <div className='choose-text-wrapper'>
                        <p className='choose-text-title'>
                          Trusted Customer Support
                        </p>
                        <p className='choose-text'>
                          we understand that your home renovation project is a
                          significant investment, and we're committed to
                          supporting you every step of the way. Our team of
                          experienced professionals is dedicated to providing
                          top-notch customer service, ensuring that you receive
                          expert advice, reliable guidance, and unwavering
                          support throughout the entire renovation process.
                        </p>
                      </div>
                    </li>

                    <li className='choose-list-item'>
                      <div className='choose-icon-wrapper'>
                        <span className='choose-list-icon'>
                          <FontAwesomeIcon icon={faSquareCheck} />
                        </span>
                      </div>
                      <div className='choose-text-wrapper'>
                        <p className='choose-text-title'>Build Relationships</p>
                        <p className='choose-text'>
                          Our commitment goes beyond hammers and nails; it's
                          about building relationships, understanding your
                          aspirations, and delivering results that exceed
                          expectations while respecting your budget.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyChooseUs;
