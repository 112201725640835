import React from "react";
import Footer from "../../components/Footer/Footer";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import { Seo } from "../../helper/SEO";

const StairProject = () => {
  const bradfordImages = [
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/stair-1.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/stair-2.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/stair-3.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/stair-4.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/stair-5.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-11.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/IMG_8494.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-13.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-14.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-1.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-2.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/IMG_8429.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/IMG_8487.webp",
  ];

  const headerTitle = "Stair Remodeling";
  const projectDescription =
    "Upgrade your stairs with TruReno! From traditional to modern styles, we transform your staircase into a practical and stylish feature!";

  return (
    <>
      <Seo
        title='TruReno | We remodel your staircase as per your needs'
        description='Upgrade your stairs with TruReno! From traditional to modern styles, we transform your staircase into a practical and stylish feature!'
        canonicalUrl='https://trureno.com/portfolio/kitchen-project'
      />
      <ImageCarousel
        slides={bradfordImages}
        headerTitle={headerTitle}
        description={projectDescription}
        hrefId={"portfolio#portfolio__cards"}
      />
      <Footer />
    </>
  );
};

export default StairProject;
