import React from "react";
import CardItem from "../CardItem/CardItem";
import "./ServiceCards.css";
import capitalizeFun from "../../helper/helper";
// import { Button } from "../Button/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";

function ServiceCards() {
  const servicesTitle = "We guarantee top-notch service for your project";
  const serviceCardsTitle = "Explore further details about our services below.";
  return (
    <>
      <div className='services-container'>
        <h1>{servicesTitle}</h1>
        <h2>All Projects Come With One Year After-Service Warranty</h2>
        {/* <div className='services-btns'>
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            pageURL='/contact'
          >
            Contact Us Now
          </Button>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            pageURL='/projects'
          >
            Check Below
            <FontAwesomeIcon
              className='fa-circlePlay'
              icon={faAnglesDown}
              beat
            />
          </Button>
        </div> */}
      </div>

      <div className='services-bottom'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 0 1000 78'
          preserveAspectRatio='none'
          data-position='bottom'
          data-ct='36510'
          decoration-type='Waves 01'
          data-css='tve-u-620e6e5f554b36'
        >
          <path d='M0,0v60c51.1-27,123.1-42,216-45c122-4,207.4,27.3,443,38c72.6,3.3,186.3-4.4,341-23V0H0z'></path>
          <path
            opacity='0.6'
            d='M1,1v60c23.1-17,81.1-27,174-30c122-4,169.4,32.3,405,43c72.6,3.3,213-11,421-43V1H1z'
          ></path>
          <path
            opacity='0.2'
            d='M1,0v62c17.8-9,73.1-15,166-18c122-4,188,18,366,18c62,0,147.7-9,314-9     c32.1,0,83.4,6,154,18V0H1z'
          ></path>
        </svg>
      </div>

      <div className='service__cards'>
        <h3> {capitalizeFun(serviceCardsTitle)} </h3>
        <div className='service__cards__container'>
          <div className='service__cards__wrapper'>
            <ul className='service__cards__items'>
              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/service-home-renovation.webp'
                text='Unleash the true potential of your home with our comprehensive house renovation solutions.'
                label='WHOLE HOUSE REMODELING'
                path=''
              />

              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/service-renovation-consultant.webp'
                text='We offer expert construction consultancy for efficient project management and successful execution.'
                label={"Renovation Consultant".toUpperCase()}
                path=''
              />
            </ul>

            <ul className='service__cards__items'>
              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/service-architecture-design.webp'
                text='Tailored architectural designs redefining spaces, blending innovation and functionality for an exceptional living experience.'
                label={"Architecture Design".toUpperCase()}
                path=''
              />

              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/service-interior-design.webp'
                text='Crafting captivating interior design solutions tailored to reflect your unique style and elevate the essence of your space.'
                label={"Interior Design".toUpperCase()}
                path=''
              />
            </ul>

            <ul className='service__cards__items'>
              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/service-house-maintenance.webp'
                text='Delivering proactive building maintenance solutions to preserve functionality, and ensure optimal performance for your property.'
                label={"House Maintenance".toUpperCase()}
                path=''
              />

              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/service-electrical-services.webp'
                text='Our licensed electrical experts ensure the success of your bathroom, basement, or kitchen renovations. For reliable electrical solutions, feel free to contact us today.'
                label={"Electrical Services".toUpperCase()}
                path=''
              />

              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-12.webp'
                text='We also offer additional services such as open concept layout adjustments (including wall removal) and property management along with service calls.'
                label={"Additional Services".toUpperCase()}
                path=''
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCards;
