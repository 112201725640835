import React from "react";
import Footer from "../../components/Footer/Footer.jsx";
import TermsOfServices from "../../components/PolicyTemplate/TermsOfService.jsx";
import { Seo } from "../../helper/SEO.js";

const TermsOfServicesPage = () => {
  return (
    <>
      <Seo
        title='TruReno | Terms of Services'
        description='TruReno Terms of Services Page'
        canonicalUrl='https://trureno.com/terms-of-service'
      />
      <TermsOfServices />
      <Footer />
    </>
  );
};

export default TermsOfServicesPage;
