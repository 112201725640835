import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faCircleExclamation,
  faCircleCheck,
  faEnvelope,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Swal from "sweetalert2";

const ContactForm = () => {
  const phoneNumberRegEx =
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$|^$/;

  const nonNumericRegEx = /[^0-9]/g;

  const emailRegex =
    // eslint-disable-next-line no-control-regex, no-useless-escape
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

  // Define Yup schema for form validation
  const schema = object().shape({
    name: string()
      .required("Please enter your name")
      .matches(nonNumericRegEx, "Please enter non-alphanumeric input"),
    email: string()
      .required("Email is required")
      .matches(emailRegex, "Please provide correct email address"),
    phoneNumber: string()
      .matches(phoneNumberRegEx, "Please enter your 10-digit phone number")
      .required("Phone number is required"),
    service: string().required("Please select a service"),
    message: string(),
  });

  const [showNotification, setShowNotification] = useState(false);

  const [loading, setLoading] = useState(false);

  const closeNotification = () => {
    setShowNotification(false);
    window.gtag_report_conversion();
  };

  const captchaRef = useRef(null);

  // Initialize React Hook Form with Yup resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: "", email: "", phoneNumber: "", subject: "" },
    resolver: yupResolver(schema),
  });

  // Handle form submission by using react hook form only
  // const onSubmit = (data) => {
  //   console.log(data); // Handle form data submission here
  //   setShowNotification(true);
  //   reset();
  // };

  // Handle form submission and send email by using EmailJS ()
  const sendEmail = async (formData, e) => {
    e.preventDefault();

    // For reCAPTCHA getting the token
    const token = captchaRef.current.getValue();

    if (!token) {
      Swal.fire({
        icon: "error",
        text: "Please kindly verify you are a human.",
      });
    } else {
      const inputVal = await e.target[0].value;

      await axios
        .post("http://localhost:2000/post", { inputVal, token })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      captchaRef.current.reset();

      setLoading(true);

      // if we want to keep react hook form methods, we need to use "emailjs.send", instead of using "emailjs.sendForm" method
      // https://www.appsloveworld.com/reactjs/200/73/typeerror-e-preventdefault-is-not-a-function-on-react-hook-form-using-emailjs?expand_article=1
      await emailjs
        .send(
          "service_vwvzv0k",
          "template_y04ygb8",
          formData,
          "he4sSaNX04F6bRJRy"
        )
        .then(
          (result) => {
            setLoading(false);
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      console.log(formData); // Handle form data submission here
      setShowNotification(true);
      reset();
    }
  };

  return (
    <section className='contact-section-container' id='free__quote'>
      <div className='contact-section-wrapper'>
        <div className='contact-section-heading'>
          <h2>Call us or write a message to get a Free Quote!</h2>
        </div>
        <div className='contact-section-content'>
          <div className='contact-section-text'>
            <div className='text-phone-wrapper'>
              <FontAwesomeIcon className='icon-phone' icon={faPhoneVolume} />
              <div className='text-phone'>
                {/* <p>Phone</p> */}
                <p>(905) 490-8089</p>
              </div>
            </div>
            <div className='text-email-wrapper'>
              <FontAwesomeIcon className='icon-email' icon={faEnvelope} />

              <div className='text-email'>
                {/* <p>Email</p> */}
                <p>support@trureno.com</p>
              </div>
            </div>
            <div className='text-img'>
              <img
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/contact-us-form-img.webp'
                alt='contact us form'
                title='contact us today'
              />
            </div>
          </div>

          <div className='contact-section-form'>
            {/* FORM SECTION */}
            <form
              className='main-form'
              onSubmit={handleSubmit(sendEmail)}
              id='contact-us-form'
              aria-label='contact us form'
            >
              <div className='input-wrapper'>
                <label className='input-label' htmlFor='name'>
                  Name (required)
                </label>
                <input
                  className={
                    !errors.name ? "input-field" : "input-field input-error"
                  }
                  type='text'
                  id='name'
                  {...register("name")}
                />
                {!!errors.name && (
                  <div className='error-wrapper'>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      style={{ color: "#c81414" }}
                    />
                    <p className='error-msg'>{errors.name.message}</p>
                  </div>
                )}
              </div>

              <div className='input-wrapper'>
                <label className='input-label' htmlFor='phoneNumber'>
                  Phone Number (required)
                </label>
                <input
                  className={
                    !errors.phoneNumber
                      ? "input-field"
                      : "input-field input-error"
                  }
                  type='text'
                  id='phoneNumber'
                  {...register("phoneNumber")}
                />
                {!!errors.phoneNumber && (
                  <div className='error-wrapper'>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      style={{ color: "#c81414" }}
                    />
                    <p className='error-msg'>{errors.phoneNumber.message}</p>
                  </div>
                )}
              </div>

              <div className='input-wrapper'>
                <label className='input-label' htmlFor='email'>
                  Email (required)
                </label>
                <input
                  className={
                    !errors.email ? "input-field" : "input-field input-error"
                  }
                  type='text'
                  id='email'
                  {...register("email")}
                />
                {!!errors.email && (
                  <div className='error-wrapper'>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      style={{ color: "#c81414" }}
                    />
                    <p className='error-msg'>{errors.email.message}</p>
                  </div>
                )}
              </div>

              <div className='input-wrapper'>
                <label className='input-label' htmlFor='service'>
                  Select Service (required)
                </label>
                <select
                  className={
                    !errors.service ? "input-field" : "input-field input-error"
                  }
                  id='service'
                  {...register("service")}
                >
                  <option value=''>Select a service</option>
                  <option value='Kitchen Renovation'>Kitchen Renovation</option>
                  <option value='Bathroom Renovation'>
                    Bathroom Renovation
                  </option>
                  <option value='Basement Renovation'>
                    Basement Renovation
                  </option>
                  <option value='Electrical Services'>
                    Electrical Services
                  </option>
                  <option value='Other'>
                    Other, please provide details below
                  </option>
                </select>
                {!!errors.service && (
                  <div className='error-wrapper'>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      style={{ color: "#c81414" }}
                    />
                    <p className='error-msg'>{errors.service.message}</p>
                  </div>
                )}
              </div>

              <div className='input-wrapper'>
                <label className='input-label' htmlFor='message'>
                  Message
                </label>
                <textarea
                  className='textarea-field'
                  id='message'
                  {...register("message")}
                  rows='5'
                  placeholder='Please type details here...'
                />
                {errors.message && <p>{errors.message.message}</p>}
              </div>

              <ReCAPTCHA
                className='recaptcha'
                ref={captchaRef}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />

              {/* <button
                form='contact-us-form'
                className='submit-btn'
                type='submit'
              >
                Send Message
              </button> */}

              {loading && (
                <div className='spinner'>
                  <FontAwesomeIcon icon={faSpinner} spinPulse />
                </div>
              )}

              <div
                className={
                  showNotification
                    ? "notification notification-open"
                    : "notification"
                }
              >
                <FontAwesomeIcon
                  className='icon-tick'
                  icon={faCircleCheck}
                  style={{ color: "#4a4a4a" }}
                />
                <h2>Thank You!</h2>
                <p>
                  Your details has been successfully submitted. We will back to
                  you soon!
                </p>
                <button onClick={closeNotification} type='button'>
                  OK
                </button>
              </div>
            </form>
          </div>
        </div>
        <button form='contact-us-form' className='submit-btn' type='submit'>
          Send Message
        </button>
      </div>
    </section>
  );
};

export default ContactForm;
