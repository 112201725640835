import React from "react";
import Footer from "../../components/Footer/Footer.jsx";
import NotFound from "../../components/NotFound/NotFound.jsx";

const NotFoundPage = () => {
  return (
    <>
      <NotFound />
      <Footer />
    </>
  );
};

export default NotFoundPage;
