import React, { useEffect, useState } from "react";
import "./Mission.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Mission() {
  const [mobileView, setMobileView] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 991) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <section className='mission-section'>
        <h3>How It Works</h3>
        <h4 className='mission-subtitle'>
          Find our stress-free approach to planning your home renovation
          project.
        </h4>
        <div className='mission-container'>
          <div className='mission-wrapper'>
            <div className='mission-inner-container'>
              <div className='container-text-side'>
                <div className='content-text-center'>
                  <ul className='content-list-container'>
                    <li className='content-list-item'>
                      <div className='content-icon-wrapper'>
                        <span className='content-list-icon'>
                          <FontAwesomeIcon icon={fa1} />
                        </span>
                      </div>
                      <div className='content-text-wrapper'>
                        <p className='content-text-title'>Initial Contact</p>
                        <p className='content-text'>
                          Get in touch with our team by phone or email, and let
                          us know about your project's idea, preferred style,
                          and budget. This first contact will help us to
                          understand your vision and transform it into your
                          dream project.
                        </p>
                      </div>
                    </li>

                    <li className='content-list-item'>
                      <div className='content-icon-wrapper'>
                        <span className='content-list-icon'>
                          <FontAwesomeIcon icon={fa2} />
                        </span>
                      </div>
                      <div className='content-text-wrapper'>
                        <p className='content-text-title'>
                          Meet Our Project Manager and Get A Free Quote
                        </p>
                        <p className='content-text'>
                          Our Project Manager will arrange a meeting with you to
                          gain a deeper understanding of your project's
                          specifics and then create a tailored proposal that
                          includes a detailed scope of work and a clear pricing
                          breakdown.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {!mobileView && window.innerWidth > 991 && (
                <div className='container-img-side'>
                  <img
                    className='mission-up-img'
                    src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/img-3.webp'
                    alt='project approach-1'
                    title='project approach-1'
                  />
                </div>
              )}
            </div>

            <div className='mission-inner-container'>
              {!mobileView && window.innerWidth > 991 && (
                <div className='container-img-side'>
                  <img
                    className='mission-up-img'
                    src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8450.webp'
                    alt='project approach-2'
                    title='project approach-2'
                  />
                </div>
              )}

              <div className='container-text-side'>
                <div className='content-text-center'>
                  <ul className='content-list-container'>
                    <li className='content-list-item'>
                      <div className='content-icon-wrapper'>
                        <span className='content-list-icon'>
                          <FontAwesomeIcon icon={fa3} />
                        </span>
                      </div>
                      <div className='content-text-wrapper'>
                        <p className='content-text-title'>
                          Design with professionals
                        </p>
                        <p className='content-text'>
                          Once your space is measured, our professional designer
                          will help you choose from existing design catalogs or
                          create a custom design based on your requirements.
                          From an initial design to detailed 3D renderings,
                          every step is based on your approved review. The
                          design and proposals are finalized only after your
                          signing off.
                        </p>
                      </div>
                    </li>

                    <li className='content-list-item'>
                      <div className='content-icon-wrapper'>
                        <span className='content-list-icon'>
                          <FontAwesomeIcon icon={fa4} />
                        </span>
                      </div>
                      <div className='content-text-wrapper'>
                        <p className='content-text-title'>
                          Completed Project Plan
                        </p>
                        <p className='content-text'>
                          We will provide a detailed project plan which includes
                          design, finish materials, and scheduled deliveries; so
                          that you can see what steps and materials are involved
                          in what time frame of your project.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='mission-inner-container'>
              <div className='container-text-side'>
                <div className='content-text-center'>
                  <ul className='content-list-container'>
                    <li className='content-list-item'>
                      <div className='content-icon-wrapper'>
                        <span className='content-list-icon'>
                          <FontAwesomeIcon icon={fa5} />
                        </span>
                      </div>
                      <div className='content-text-wrapper'>
                        <p className='content-text-title'>Project Execution</p>
                        <p className='content-text'>
                          The project commences with demolition, succeeded by
                          construction, finishing touches, and cleanup.
                          Scheduled inspections will be arranged as required.
                          We'll keep you informed of any changes or new
                          suggestions. Our skilled crews ensure adherence to
                          plans and prioritize your complete satisfaction.
                        </p>
                      </div>
                    </li>

                    <li className='content-list-item'>
                      <div className='content-icon-wrapper'>
                        <span className='content-list-icon'>
                          <FontAwesomeIcon icon={fa6} />
                        </span>
                      </div>
                      <div className='content-text-wrapper'>
                        <p className='content-text-title'>
                          Quality Assurance After Project
                        </p>
                        <p className='content-text'>
                          After the completion of your new renovation, our team
                          will guide you through a final inspection to ensure
                          your complete satisfaction with the project.
                        </p>
                        <p
                          className='content-text'
                          style={{ fontWeight: 400, marginTop: "0.5rem" }}
                        >
                          But our service doesn't stop there. We also provide
                          additional warranty services. Within a year of project
                          completion, if there are any renovation issues caused
                          by our oversight, we'll offer free repair services.
                          <Link className="about-us-internal-link" to='/about-us'>
                          &nbsp;More info please check our principles.
                          </Link>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {!mobileView && window.innerWidth > 991 && (
                <div className='container-img-side img-side-last'>
                  <img
                    className='mission-up-img'
                    src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/4-3/IMG_8684.webp'
                    alt='project approach-3'
                    title='project approach-3'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mission;
