import React from "react";
import Footer from "../../components/Footer/Footer";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import { Seo } from "../../helper/SEO";

const WholeHouseRemodelProject = () => {
  const wholeHouseRenoImages = [
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-1.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-2.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-3.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-4.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-5.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-6.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-7.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-8.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-9.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-10.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-11.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-12.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-13.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-14.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-15.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-4.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-oak-17.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-1.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-2.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-3.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-4.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-5.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-6.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-7.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-8.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-9.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-10.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-11.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-12.webp",
    "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/whole-house-reno/whole-house-reno-other-13.webp",
  ];

  const headerTitle = "Whole House Complete Remodeling";

  const projectDescription =
    "Renovate your entire home with TruReno! We focus on every corner from ceiling to floor, creating a comfortable space that reflects your unique style!";

  return (
    <>
      <Seo
        title='TruReno | Check out our whole-house renovation project!'
        description='Renovate your entire home with TruReno! We focus on every corner from ceiling to floor, creating a comfortable space that reflects your unique style!'
        canonicalUrl='https://trureno.com/portfolio/whole-house-project'
      />
      <ImageCarousel
        slides={wholeHouseRenoImages}
        headerTitle={headerTitle}
        description={projectDescription}
        hrefId={"portfolio#portfolio__cards"}
      />
      <Footer />
    </>
  );
};

export default WholeHouseRemodelProject;
