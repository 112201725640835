import "./App.css";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./pages/Services/Services";
import ContactUs from "./pages/ContactUs/ContactUs";
import AboutUs from "./pages/AboutUs/AboutUs";
// import FreeQuote from "./pages/FreeQuote/FreeQuote";
import Portfolio from "./pages/Portfolio/Portfolio";
import LinkToTop from "./helper/LinkToTop";
import PrivacyPolicyPage from "./pages/Policies/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/Policies/TermsOfServicePage";
import OakvilleProject from "./pages/Projects/OakvilleProject";
import WholeHouseRemodelProject from "./pages/Projects/WholeHouseRemodelProject";
import BathroomProject from "./pages/Projects/BathroomProject";
import KitchenProject from "./pages/Projects/KitchenProject";
import BasementProject from "./pages/Projects/BasementProject";
import StairProject from "./pages/Projects/StairProject";

// importing the provider
import { HelmetProvider } from 'react-helmet-async';
import NotFoundPage from "./pages/NotFound/NotFoundPage";


function App() {
  return (
    <>
      <HelmetProvider>
        <Router>
          <LinkToTop />
          <Navbar />
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/contact' element={<ContactUs />} />
            {/* <Route path='/free-quote' element={<FreeQuote />} /> */}
            <Route
              path='/recent-project-oakville'
              element={<OakvilleProject />}
            />
            <Route
              path='/portfolio/whole-house-project'
              element={<WholeHouseRemodelProject />}
            />
            <Route
              path='/portfolio/kitchen-project'
              element={<KitchenProject />}
            />
            <Route
              path='/portfolio/bathroom-project'
              element={<BathroomProject />}
            />
            <Route
              path='/portfolio/basement-project'
              element={<BasementProject />}
            />
            <Route path='/portfolio/stair-project' element={<StairProject />} />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
            <Route path='/terms-of-service' element={<TermsOfServicePage />} />
            <Route path='*' element={<NotFoundPage />} status={404} />
          </Routes>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
