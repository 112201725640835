import React from "react";
import Footer from "../../components/Footer/Footer.jsx";
import PrivacyPolicy from "../../components/PolicyTemplate/PrivacyPolicy.jsx";
import { Seo } from "../../helper/SEO.js";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Seo
        title='TruReno | Privacy Policy'
        description='TruReno Privacy Policy Page'
        canonicalUrl='https://trureno.com/privacy-policy'
      />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
