import React from "react";
import "./PortfolioCards.css";
import capitalizeFun from "../../helper/helper";
import { Button } from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import CardItem from "../CardItem/CardItem";

function PortfolioCards() {
  const portfolioTitle = "Discover the space, and renovate your dream home with us!";
  const portfolioSubTitle =
    "Explore our renovations with exquisite materials, optimal lighting, and exceptional craftsmanship.";

  const portfolioCardsTitle =
    "Find the beauty of newly renovated whole house, kitchen and other living spaces.";

  return (
    <>
      <div className='portfolio-container'>
        <h1>{portfolioTitle}</h1>
        <h2>{portfolioSubTitle}</h2>
        <div className='portfolio-btns'>
          <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            pageURL='/contact'
          >
            Contact Us Now
          </Button>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            pageURL='/recent-project-oakville'
          >
            Ongoing Project
            <FontAwesomeIcon
              className='fa-circlePlay'
              icon={faCirclePlay}
              beat
            />
          </Button>
        </div>
      </div>
      <div className='portfolio-bottom'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 0 1000 78'
          preserveAspectRatio='none'
          data-position='bottom'
          data-ct='36510'
          decoration-type='Waves 01'
          data-css='tve-u-620e6e5f554b36'
        >
          <path d='M0,0v60c51.1-27,123.1-42,216-45c122-4,207.4,27.3,443,38c72.6,3.3,186.3-4.4,341-23V0H0z'></path>
          <path
            opacity='0.6'
            d='M1,1v60c23.1-17,81.1-27,174-30c122-4,169.4,32.3,405,43c72.6,3.3,213-11,421-43V1H1z'
          ></path>
          <path
            opacity='0.2'
            d='M1,0v62c17.8-9,73.1-15,166-18c122-4,188,18,366,18c62,0,147.7-9,314-9     c32.1,0,83.4,6,154,18V0H1z'
          ></path>
        </svg>
      </div>

      <div className='portfolio__cards' id='portfolio__cards'>
        <h3> {capitalizeFun(portfolioCardsTitle)} </h3>
        <div className='portfolio__cards__container'>
          <div className='portfolio__cards__wrapper'>
            <ul className='portfolio__cards__items'>
              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8675.webp'
                label='Whole House Remodeling'
                path='/portfolio/whole-house-project'
              />

              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/kitchen/kitchen-15.webp'
                label='Kitchen Remodeling'
                path='/portfolio/kitchen-project'
              />
            </ul>

            <ul className='portfolio__cards__items'>
              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-2.webp'
                label='Bathroom Remodeling'
                path='/portfolio/bathroom-project'
              />

              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-4.webp'
                label='Basement Remodeling'
                path='/portfolio/basement-project'
              />

              <CardItem
                src='https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/stair/stair-2.webp'
                label='Stairs Remodeling'
                path='/portfolio/stair-project'
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default PortfolioCards;
