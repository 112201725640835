import React from "react";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div>
      <div className='notFound-container'>
      </div>

      <div className='notFound-bottom'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 0 1000 78'
          preserveAspectRatio='none'
          data-position='bottom'
          data-ct='36510'
          decoration-type='Waves 01'
          data-css='tve-u-620e6e5f554b36'
        >
          <path d='M0,0v60c51.1-27,123.1-42,216-45c122-4,207.4,27.3,443,38c72.6,3.3,186.3-4.4,341-23V0H0z'></path>
          <path
            opacity='0.6'
            d='M1,1v60c23.1-17,81.1-27,174-30c122-4,169.4,32.3,405,43c72.6,3.3,213-11,421-43V1H1z'
          ></path>
          <path
            opacity='0.2'
            d='M1,0v62c17.8-9,73.1-15,166-18c122-4,188,18,366,18c62,0,147.7-9,314-9     c32.1,0,83.4,6,154,18V0H1z'
          ></path>
        </svg>
      </div>

      <div className='notFound-content'>
        <h2>404 Error</h2>
        <h2>Page Not Found</h2>

        
      </div>
    </div>
  );
};

export default NotFound;
