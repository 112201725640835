import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./CardItem.css";

function CardItem({ path, label, text, src, objectFit }) {
  const currentPathname = useLocation().pathname.replace(/\//g, "");

  return (
    <>
      <li className='cards__item'>
        <Link
          className={!!path ? "cards__item__link" : "cards__item__link noLink"}
          to={path}
          title={
            !!label
              ? `${label.toLowerCase()}-${currentPathname}-link`
              : "current project link"
          }
        >
          <figure
            className={
              !!text
                ? "cards__item__pic__wrap"
                : "cards__item__pic__wrap__no__text"
            }
            data-category={label}
          >
            <img
              src={src}
              alt={
                !!label
                  ? `${label.toLowerCase()}-${currentPathname}-demo`
                  : "current project demo"
              }
              title={
                label
                  ? `${label.toLowerCase()}-${currentPathname}-demo`
                  : "current project demo"
              }
              className={
                !!text ? "cards__item__img" : "cards__item__img__without__text"
              }
              style={{ objectFit: `${objectFit}` }}
            />
          </figure>
          {!!label && <div className='card__label'>{label}</div>}

          {!!text && (
            <div className='cards__item__info'>
              <h4 className='cards__item__text'>{text}</h4>
            </div>
          )}
        </Link>
      </li>
    </>
  );
}

export default CardItem;
