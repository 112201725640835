import React from 'react'
import Footer from "../../components/Footer/Footer";
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import { Seo } from "../../helper/SEO";

const BathroomProject = () => {
  
    // If not slides attribute for ImageCarousel, then use video instead
    // const videoSource = [
    //   "https://trureno-assets.s3.ca-central-1.amazonaws.com/videos/aurora-trimmed.mp4",
    // ];

      const bathroomRemodelingImages = [
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-2.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/2085-WEBP/16-9/IMG_8729.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-3.jpg",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-5.jpg",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-4.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-6.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-7.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-8.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-9.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-10.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-11.jpg",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-12.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-13.jpg",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-14.jpg",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-15.webp",
        "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/bathroom/bathroom-16.webp",
      ];

    const headerTitle = 'Bathroom Remodeling';

    const projectDescription =
      "Our expert team renovates outdated spaces into luxurious retreats. From fixtures to finishes, we renovate your dream bathroom to life!";

    // const projectDescription =
    //   "For this project in Aurora, we remodeled the entire basement area, including adding new rooms and divisions, installing new flooring and performing new electrical work. We also ordered new cabinets, applied paint, created smooth ceilings, and improved the lighting.";

  return (
    <>
      <Seo
        title='TruReno | Looking for a bathroom remodeling contractor?'
        description='Our expert team transforms outdated spaces into luxurious retreats. From fixtures to finishes, we renovate your dream bathroom to life!'
        canonicalUrl='https://trureno.com/portfolio/bathroom-project'
      />
      <ImageCarousel
        slides={bathroomRemodelingImages}
        headerTitle={headerTitle}
        description={projectDescription}
        hrefId={"portfolio#portfolio__cards"}
      />
      <Footer />
    </>
  );
}

export default BathroomProject;