import React from "react";
import Footer from "../../components/Footer/Footer";
import ServiceCards from "../../components/ServiceCards/ServiceCards.jsx";
import { Seo } from "../../helper/SEO.js";

const Services = () => {

  return (
    <>
      <Seo
        title='TruReno | Enjoy our top-notch services in the Toronto area.'
        description='We offer comprehensive home improvement services, providing professional advice and ensuring successful project implementation.'
        canonicalUrl='https://trureno.com/services'
      />
      <ServiceCards />
      <Footer />
    </>
  );
};

export default Services;
