import React from 'react'
import Footer from "../../components/Footer/Footer";
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import { Seo } from "../../helper/SEO";

const BasementProject = () => {
    const bradfordImages = [
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-2.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-3.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-4.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-5.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-6.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-7.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-8.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-9.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-10.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-11.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-12.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-13.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-14.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-15.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-16.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-17.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-18.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-19.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-20.webp",
      "https://trureno-assets.s3.ca-central-1.amazonaws.com/images/WEBP/basement/basement-1.webp",
    ];

    const headerTitle = 'Basement Remodeling'
    const projectDescription =
      "Transform your basement with TruReno! We specialize in remodeling basements to maximize space and comfort. Elevate your home with our expert team!";

  return (
    <>
      <Seo
        title='TruReno | Check out our recent basement remodeling project!'
        description='Transform your basement with TruReno! We specialize in remodeling basements to maximize space and comfort. Elevate your home with our expert team!'
        canonicalUrl='https://trureno.com/portfolio/basement-project'
      />
      <ImageCarousel
        slides={bradfordImages}
        headerTitle={headerTitle}
        description={projectDescription}
        hrefId={"portfolio#portfolio__cards"}
      />
      <Footer />
    </>
  );
}

export default BasementProject;